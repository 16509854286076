import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../styles/booking.css';
import Footer from '../components/footer';


const termsAndConditions = `
  Welcome to Swing Golf Sim and Lounge (“Venue”). These Terms and Conditions govern your use of the Venue's facilities and services. 
  By entering and using the Venue, you agree to comply with and be bound by these Terms and Conditions.

  Advanced booking is recommended to ensure the availability of simulators. Reservations can be made online, by phone, or in person. 
  A valid credit card is required to secure a reservation. Cancellations must be made at least 24 hours in advance to avoid cancellation fees.

  All fees for simulator usage, food, and beverages must be paid in full before leaving the Venue. Prices are subject to change without prior notice. 
  We accept cash, credit, and debit cards.

  Guests must respect the Venue, staff, and other patrons at all times. Any form of harassment, abuse, or disruptive behaviour will not be tolerated 
  and may result in removal from the Venue.

  Guests must follow all instructions provided by staff regarding the use of golf simulators. Guests are responsible for any damages caused to the 
  simulators due to reckless behaviour.

  Outside food and beverages are not permitted. The Venue offers a variety of food and beverages for purchase. Responsible consumption of alcohol 
  is expected; the Venue reserves the right to refuse service to intoxicated guests.

  The Venue is not responsible for any personal injury, loss, or damage to personal property while on the premises. Guests assume all risks associated 
  with the use of the Venue and its facilities.

  Personal information collected during bookings will be used in accordance with our Privacy Policy. We do not share personal information with third parties without consent.

  The Venue reserves the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on our website or at the Venue.

  These Terms and Conditions shall be governed by and construed in accordance with the laws of Trinidad and Tobago.

  For any questions or concerns regarding these Terms and Conditions, please contact us at swingtthelp@gmail.com.

  By using our facilities, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
`;


const WI_PAY_TAX = 0.035; // WiPay tax rate
const FIXED_USD_FEE = 0.25; // WiPay fixed USD fee
const USD_TO_TTD_RATE = 6.80; // USD to TTD conversion rate

const BookingPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: moment().format('YYYY-MM-DD'),
    time: '',
    duration: 1,
    simulator: 1,
    lesson: 0,
    groupSize: 1 
  });
  const [maxDuration, setMaxDuration] = useState(1);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  useEffect(() => {
    fetchBookings();
  }, []);

  useEffect(() => {
    if (bookingDetails.date) {
      filterAvailableSlotsForDay(bookingDetails.date);
    }
  }, [selectedDate, bookings, bookingDetails.duration]);

  const fetchBookings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NGROK}/api/bookings/bookings`);
      setBookings(response.data); // Store all bookings
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const filterAvailableSlotsForDay = (date) => {
    const now = new Date(); // Current time
    const today = moment().startOf('day'); // Start of today
    const selectedDay = moment(date).startOf('day'); // Start of the selected day
  
    // Block all slots for dates before today
    if (selectedDay.isBefore(today)) {
      const unavailableSlots = generateHourlyIntervals(selectedDay.day()).map((time) => ({
        time,
        sim1Available: false,
        sim2Available: false,
        isUnavailable: true,
      }));
      setAvailableTimeSlots(unavailableSlots);
      return;
    }
  
    const dayOfWeek = selectedDay.day();
    const intervals = generateHourlyIntervals(dayOfWeek);
    const bookingsForDate = bookings.filter(
      (booking) => moment(booking.date).format('YYYY-MM-DD') === date
    );
  
    const availableSlots = intervals.map((time) => {
      // Check if the slot is unavailable (past time) only for today
      const isToday = selectedDay.isSame(today, 'day');
      const isPastTime = isToday && moment(`${date} ${time}`, 'YYYY-MM-DD h:mm A').isBefore(moment());
  
      // Determine simulator availability
      const sim1Available = !isPastTime && isSlotAvailable(time, 1, bookingsForDate);
      const sim2Available = !isPastTime && isSlotAvailable(time, 2, bookingsForDate);
  
      return {
        time,
        sim1Available,
        sim2Available,
        isUnavailable: isPastTime, // Mark past slots as unavailable
      };
    });
  
    setAvailableTimeSlots(availableSlots);
  };
  
  
  
  
  
  

  const calculateMaxDuration = (selectedTime, selectedSimulator) => {
    const combinedDateTime = combineDateTime(bookingDetails.date, selectedTime);
    const closingTime = getClosingTime(combinedDateTime);
    
    // Calculate maximum duration based on closing time
    let maxAvailableDuration = Math.floor((closingTime - combinedDateTime) / (30 * 60 * 1000)) / 2;
    
    // Find the next booking on the same simulator after the selected time
    const nextBooking = bookings
      .filter((booking) => booking.simulator === selectedSimulator && new Date(booking.date) > combinedDateTime)
      .sort((a, b) => new Date(a.date) - new Date(b.date))[0];
    
    if (nextBooking) {
      const nextBookingStart = new Date(nextBooking.date);
      const availableUntilNextBooking = Math.floor((nextBookingStart - combinedDateTime) / (30 * 60 * 1000)) / 2;
      
      maxAvailableDuration = Math.min(maxAvailableDuration, availableUntilNextBooking);
    }
    
    setMaxDuration(maxAvailableDuration > 0 ? maxAvailableDuration : 1);
  };
  
  
  const isSlotAvailable = (startTime, simulator, bookingsForDate) => {
    const duration = bookingDetails.duration;
    const startMoment = moment(`${bookingDetails.date} ${startTime}`, 'YYYY-MM-DD h:mm A');
  
    for (let i = 0; i < duration; i++) {
      const checkMoment = startMoment.clone().add(i, 'hours');
      const isBooked = bookingsForDate.some((booking) => {
        const bookingStart = moment(booking.date);
        const bookingEnd = moment(bookingStart).add(booking.duration, 'hours');
  
        return (
          booking.simulator === simulator &&
          checkMoment.isBetween(bookingStart, bookingEnd, null, '[)')
        );
      });
  
      if (isBooked) {
        return false;
      }
    }
  
    return true;
  };
  
  
// Updated generateDurationOptions function with simplified logic
const durationOptionsByStartTime = {
  "12:00 PM": [0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  "12:30 PM": [0.5, 1, 1.5, 2, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5, 11.5],
  "1:00 PM": [0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  "1:30 PM": [0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5],
  "2:00 PM": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  "3:00 PM": [1, 2, 3, 4, 5, 6, 7, 8, 9],
  "4:00 PM": [1, 2, 3, 4, 5, 6, 7, 8],
  "5:00 PM": [1, 2, 3, 4, 5, 6, 7],
  "6:00 PM": [1, 2, 3, 4, 5, 6],
  "7:00 PM": [1, 2, 3, 4, 5],
  "8:00 PM": [1, 2, 3, 4],
  "9:00 PM": [1, 2, 3],
  "10:00 PM": [1, 2],
  "11:00 PM": [1] // Midnight closing
};


// Updated function to check for midnight closing based on date
const isMidnightClosing = (date) => {
  const dayOfWeek = new Date(date).getDay();
  return dayOfWeek === 4 || dayOfWeek === 5; // Friday (5) and Saturday (6)
};

// Updated generateDurationOptions function
const generateDurationOptions = (startTime, isMidnightClosing, maxDuration) => {
  const durations = durationOptionsByStartTime[startTime] || [];

  // Check if startTime has a valid time format before proceeding
  if (!startTime.match(/(\d+):(\d+) ([AP]M)/)) {
    return []; // Return an empty array if no match is found
  }

  const [hours, minutes, period] = startTime.match(/(\d+):(\d+) ([AP]M)/).slice(1);
  const hour = parseInt(hours, 10) + (period === 'PM' && hours !== '12' ? 12 : 0);
  const filteredDurations = durations.filter(duration => 
    duration <= maxDuration && 
    (hour < 14 || duration % 1 === 0) 
  );

  // Conditionally exclude the last option only when closing is before midnight
  const adjustedDurations = isMidnightClosing ? filteredDurations : [...filteredDurations];

  return adjustedDurations.map(duration => (
    <option key={duration} value={duration}>
      {duration} Hour{duration > 1 ? 's' : ''}
    </option>
  ));
};





const generateHourlyIntervals = (dayOfWeek) => {
  const intervals = [];
  const startHour = 12; // Updated opening time to 12 PM
  const endHour = (dayOfWeek === 4 || dayOfWeek === 5) ? 24 : 23; // Midnight for Friday/Saturday, 11 PM otherwise

  for (let i = startHour; i < endHour; i++) {
    // Half-hour intervals until 2 PM
    if (i < 14) {
      intervals.push(`${i % 12 === 0 ? 12 : i % 12}:00 ${i < 12 ? 'AM' : 'PM'}`);
      intervals.push(`${i % 12 === 0 ? 12 : i % 12}:30 ${i < 12 ? 'AM' : 'PM'}`);
    } else {
      // Full-hour intervals after 2 PM
      intervals.push(`${i % 12 === 0 ? 12 : i % 12}:00 ${i < 12 ? 'AM' : 'PM'}`);
    }
  }
  return intervals;
};


  // Corrected getClosingTime function
  const getClosingTime = (date) => {
    const dayOfWeek = new Date(date).getDay();
    const closingTime = new Date(date);

    if (dayOfWeek === 5 || dayOfWeek === 6) {
      closingTime.setHours(24, 0, 0, 0); // Midnight (12 AM) for Friday and Saturday
    } else {
      closingTime.setHours(23, 0, 0, 0); // 11 PM for other days
    }

    return closingTime;
  };

  const handleCheckout = () => {
    if (!bookingDetails.firstName || !bookingDetails.lastName || !bookingDetails.email || !bookingDetails.phone || !bookingDetails.date || !bookingDetails.time || !bookingDetails.duration|| !bookingDetails.groupSize) {
      alert('Please fill in all required fields.');
      return;
    }

    // Show terms popup before proceeding
    setShowTerms(true);
  };

  const proceedWithBooking = async () => {
    if (!isAgreed) {
      alert('You must agree to the terms and conditions.');
      return;
    }
  
    const { duration, time, date, firstName, lastName, email, phone, simulator, groupSize } = bookingDetails;
  
    if (!time) {
      alert('Please select a valid time for your booking.');
      return;
    }
  
    const combinedDateTime = combineDateTime(date, time);
    const now = new Date();
  
    if (combinedDateTime <= now) {
      alert('You cannot book for a past date or time.');
      return;
    }
  
    const oneHourBeforeBooking = new Date(combinedDateTime.getTime() - 60 * 60 * 1000);
    if (now > oneHourBeforeBooking) {
      alert('Bookings must be made at least one hour in advance.');
      return;
    }
  
    const bookingEndTime = new Date(combinedDateTime);
    bookingEndTime.setTime(combinedDateTime.getTime() + duration * 60 * 60 * 1000);
  
    const closingTime = getClosingTime(combinedDateTime);
    if (bookingEndTime > closingTime) {
      alert('The selected time slot exceeds the closing time. Please select a different time or reduce the duration.');
      return;
    }
  
    const totalPrice = getPricing(date, duration, time);
    console.log('Total Price:', totalPrice);
  
    const fixedFeeInTTD = FIXED_USD_FEE * USD_TO_TTD_RATE;
    const adjustedAmount = (totalPrice - fixedFeeInTTD) / (1 + WI_PAY_TAX);
  
    const bookingDetailsForSubmission = {
      firstName,
      lastName,
      email,
      phone,
      date: combinedDateTime.toISOString(),
      amount: adjustedAmount.toFixed(2),
      duration,
      simulator,
      lesson: 0,
      groupSize,
    };
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_NGROK}/api/bookings/initiate`, {
        ...bookingDetailsForSubmission,
        key: '458xf4izac',
        redirect_url: `/api/bookings/confirmation?bookingDetails=${encodeURIComponent(
          JSON.stringify(bookingDetailsForSubmission)
        )}`,
      });
  
      const { payment_url } = response.data;
      window.location.href = payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };
  
  

  const isPeakTime = (date, time) => {
    const bookingDate = new Date(date);
    const dayOfWeek = bookingDate.getDay(); // 0 = Sunday, 6 = Saturday
    let [timePart, period] = time.split(' ');
    let [hour] = timePart.split(':').map(Number);
  
    if (period === 'PM' && hour !== 12) hour += 12;
    else if (period === 'AM' && hour === 12) hour = 0;
  
    // Off-peak hours are now all day Monday to Thursday and Sundays,
    // and from 11 AM to 5 PM on Fridays and Saturdays.
    if (
      (dayOfWeek >= 1 && dayOfWeek <= 3) || // Monday to Thursday
      (dayOfWeek === 6) || // Sunday
      (dayOfWeek === 4 && hour >= 11 && hour < 17) || // Friday 11 AM - 5 PM
      (dayOfWeek === 4 && hour >= 11 && hour < 17) // Saturday 11 AM - 5 PM
    ) {
      return false;
    }
  
    // Peak hours are Friday 5 PM to 12 AM and Saturday 5 PM to 12 AM.
    if (
      (dayOfWeek === 4 && hour >= 17 && hour <= 24) || // Friday 5 PM to midnight
      (dayOfWeek === 5 && hour >= 17 && hour <= 24) // Saturday 5 PM to midnight
    ) {
      return true;
    }
  
    return false;
  };
  

  const getPricing = (bookingDate, duration, startTime) => {
    const dayOfWeek = new Date(bookingDate).getDay();
    const startMoment = moment(`${bookingDate} ${startTime}`, "YYYY-MM-DD h:mm A");
    let remainingDuration = duration;
    let totalPrice = 0;
  
    while (remainingDuration > 0) {
      const currentHour = startMoment.hour();
      const isPeak =
        (dayOfWeek === 4 || dayOfWeek === 5) && currentHour >= 17; // Friday or Saturday after 5 PM
  
      let rate = 0;
  
      if (currentHour < 14) {
        rate = 150; // Before 2 PM
      } else if (isPeak) {
        rate = 300; // Peak hours
      } else {
        rate = 200; // After 2 PM, but not peak
      }
  
      totalPrice += rate;
      remainingDuration -= 0.5;
      startMoment.add(30, "minutes");
    }
  
    return totalPrice;
  };
  
  
  
  const combineDateTime = (date, time) => {
    const [hourMinute, ampm] = time.split(' ');
    let [hour, minute] = hourMinute.split(':');
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);
  
    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }
  
    const newDate = new Date(`${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`);
    const openingTime = new Date(newDate);
    openingTime.setHours(12, 0, 0, 0); // Open at 12:00 PM
  
    if (newDate < openingTime) {
      throw new Error('Bookings cannot be made before 12:00 PM.');
    }
  
    return newDate;
  };
  
  // Helper function to check if the selected date is a day with midnight closing



  return (
    <div className="admin-dashboard-container">
      <div className="left-section">
        <h1>Book a Simulator</h1>
        <div className="calendar-container">
        <Calendar
          value={selectedDate}
          onChange={(date) => {
            setSelectedDate(date); // Update selectedDate correctly
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setBookingDetails({ ...bookingDetails, date: formattedDate });
            filterAvailableSlotsForDay(formattedDate);
            setSelectedTimeSlot(null);
          }}
        />
      </div>
        <h3 className="simulator-titles">Available Time Slots</h3>
        <div className="bookings-container">
  <div className="simulator-column">
    <div className="simulator-titles">
      <h3>Sim Zone (Multisport)</h3>
    </div>
    {availableTimeSlots.map((slot, index) => (
      <div key={index} className="time-slot">
        <p
          className={`${slot.sim1Available ? 'available' : 'unavailable'} ${slot.isUnavailable ? 'past-time' : ''} ${
            selectedTimeSlot === `${slot.time}-Sim Zone` ? 'selected' : ''
          }`}
          onClick={
            slot.sim1Available && !slot.isUnavailable
              ? () => {
                  setBookingDetails({ ...bookingDetails, time: slot.time, simulator: 1 });
                  setSelectedTimeSlot(`${slot.time}-Sim Zone`);
                  calculateMaxDuration(slot.time, 1);
                }
              : null
          }
        >
          {slot.time}
        </p>
      </div>
    ))}
  </div>



          <div className="simulator-column">
            <div className="simulator-titles">
              <h3>Golf Den</h3>
            </div>
            {availableTimeSlots.map((slot, index) => (
              <div key={index} className="time-slot">
                <p
                  className={`${slot.sim2Available ? 'available' : 'unavailable'} ${slot.isPast ? 'past-time' : ''} ${
                    selectedTimeSlot === `${slot.time}-Golf Den` ? 'selected' : ''
                  }`}
                  onClick={
                    slot.sim2Available && !slot.isPast
                      ? () => {
                          setBookingDetails({ ...bookingDetails, time: slot.time, simulator: 2 });
                          setSelectedTimeSlot(`${slot.time}-Golf Den`);
                          calculateMaxDuration(slot.time, 2);
                        }
                      : null
                  }
                >
                  {slot.time}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className="right-section">
  <h3>Create a Booking</h3>
  <div className="form-container">
    <input
      type="text"
      name="firstName"
      placeholder="First Name"
      value={bookingDetails.firstName}
      onChange={(e) => setBookingDetails({ ...bookingDetails, firstName: e.target.value })}
      className="custom-input"
    />
    <input
      type="text"
      name="lastName"
      placeholder="Last Name"
      value={bookingDetails.lastName}
      onChange={(e) => setBookingDetails({ ...bookingDetails, lastName: e.target.value })}
      className="custom-input"
    />
    <input
      type="email"
      name="email"
      placeholder="Email"
      value={bookingDetails.email}
      onChange={(e) => setBookingDetails({ ...bookingDetails, email: e.target.value })}
      className="custom-input"
    />
    <input
      type="tel"
      name="phone"
      placeholder="Phone"
      value={bookingDetails.phone}
      onChange={(e) => setBookingDetails({ ...bookingDetails, phone: e.target.value })}
      className="custom-input"
    />
<select
  name="duration"
  value={bookingDetails.duration}
  onChange={(e) => setBookingDetails({ ...bookingDetails, duration: parseFloat(e.target.value) })}
  className="custom-datepicker"
>
  {generateDurationOptions(bookingDetails.time, isMidnightClosing(bookingDetails.date), maxDuration)}
</select>





    <select
  name="groupSize"
  value={bookingDetails.groupSize}
  onChange={(e) => setBookingDetails({ ...bookingDetails, groupSize: parseInt(e.target.value) })}
  className="custom-datepicker"
>
  <option value={1}>1-6</option>   {/* Represents group size 1-6 */}
  <option value={2}>6+</option>    {/* Represents group size 6+ */}
</select>

    <button className="create-btn" onClick={handleCheckout}>
      Submit Booking
    </button>
  </div>

  {/* Pricing Table */}
  <div className="pricing-table">
  <h3>Pricing</h3>
  <table>
    <thead>
      <tr>
        <th>Service</th>
        <th>Pricing Details</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Simulator Session (Half Hour, Everyday, 12PM - 2PM)</td>
        <td>$150</td>
      </tr>
      <tr>
        <td>Simulator Session (Monday - Thursday & Sunday, 2PM - Closing)</td>
        <td>$400 per Hour</td>
      </tr>
      <tr>
        <td>Simulator Session (Friday & Saturday,  2PM - 5 PM)</td>
        <td>$400 per Hour</td>
      </tr>
      <tr>
        <td>Simulator Session (Friday & Saturday, 5 PM - Closing)</td>
        <td>$600 per Hour</td>
      </tr>
      <tr>
        <td>Golf Lessons</td>
        <td>$500 per Hour (Not available during Friday & Saturday evenings)</td>
      </tr>
    </tbody>
  </table>
</div>

</div>


      {showTerms && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Terms and Conditions</h2>
            <p>{termsAndConditions}</p>
            <div className="agree-section">
              <label>
                <input type="checkbox" checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} />
                I agree to the Terms and Conditions
              </label>
            </div>
            <div className="buttons">
              <button className="continue-button" onClick={proceedWithBooking} disabled={!isAgreed}>
                Continue
              </button>
              <button className="close-button" onClick={() => setShowTerms(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};


export default BookingPage;